import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import "../../../Styles/main.scss"
import { PremiumIcon, PremiumIconSmall } from "./SvgIcons"

function GoPremiumCard(props: { text: string; shape: "long" | "tall" }) {
    const navigate = useNavigate()
    const { id } = useParams()

    const onGoPremiumScreenNavigation = () => {
        navigate(`/go-premium/${id}`)
        window.scrollTo(0, 0)
    }

    return (
        <div onClick={onGoPremiumScreenNavigation} className={`go-premium-card-${props.shape}`}>
            <div className={`go-premium-card-${props.shape}__content`}>
                <div className={`go-premium-card-${props.shape}__icon`}>
                    <PremiumIcon />
                </div>
                <div className={`go-premium-card-${props.shape}__icon-small`}>
                    <PremiumIconSmall />
                </div>
                <div className={`go-premium-card-${props.shape}__text`}>{props.text}</div>
            </div>
        </div>
    )
}

export default GoPremiumCard
