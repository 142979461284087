import { NavigateFunction, Location } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { User } from "../../../Types/User"
import { getLoadingStatus } from "../../Shared/SharedSlices/LoadingScreenSlice"
import { setIsOwner, setOrganizationData } from "../../Shared/SharedSlices/OrganizationsSlice"

export const getOrganizationData = (orgId: string) => {
    return HTTPService.get(`/role-in-org/${orgId}/`, headers)
}

export const GetOrganizationRequest = (id: string | undefined, user: User, navigate?: NavigateFunction, location?: Location) => async (dispatch: Function) => {
    try {
        const otherOrganizationPathnames = [
            `/:otherOrgId/organization-profile`,
            `/:otherOrgId/organization-rooms`,
            `/:otherOrgId/organization-employees`,
            `/organization-profile/:id/:otherOrgId`,
            `/organization-rooms/:id/:otherOrgId`,
            `/organization-employees/:id/:otherOrgId`,
            `/organization-profile/:id/:otherOrgId/event/:eventId`,
            `/organization-profile/:otherOrgId/event/:eventId`,
            `/organization-profile/:otherOrgId/event/`,
        ]
        const response = await getOrganizationData(id!)
        if (response.status === 200) {
            if (response.data.is_admin) {
                dispatch(setIsOwner(response.data.is_owner))
                dispatch(setOrganizationData(response.data.organization))
            } else {
                if (!otherOrganizationPathnames.includes(location?.pathname!) && location?.pathname !== undefined) {
                    dispatch(getLoadingStatus(false))
                    navigate && navigate("/no-permission")
                }
            }
        }
    } catch (error: any) {
        if (error.response.status === 404) {
            navigate && navigate("/no-permission")
            window.scrollTo(0, 0)
        } else {
            console.log(error)
        }
    }
}
