import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import "../../../Styles/main.scss"
import HeaderBurgerMenu from "./HeaderBurgerMenu"
import HeaderMobileMenu from "./HeaderMobileMenu"
import HeaderProfileMenu from "./HeaderProfileMenu"
import HeaderHelpMenu from "./HeaderHelpMenu"
import SearchInput from "../Primitive/SearchInput"
import { ChatIcon, ChatIconActive, EdusiiaSmallIcon, HomeIcon, MediumLogoIcon, MessageActiveIcon, MessageIcon, SmallChatIcon, SmallChatIconActive } from "../Components/SvgIcons"
import { RootState } from "../../../App/store"
import { RoleType, SearchFilter, StorageKey } from "../../../App/enums"
import SearchDropdown from "../Search/SearchDropdown"
import { onGetGlobalSearchFilteredData } from "../Search/GlobalSearchServiceRequest"
import { setGlobalSearchData } from "../Search/GlobalSearchSlice"
import SessionService from "../../../App/SessionService"
import { setRoleStatusData } from "../SharedSlices/RolesSlice"
import { setIsOwner, setOrganizationData } from "../SharedSlices/OrganizationsSlice"
import { userNotificationGetRequest } from "../../Notifications/UserNotificationServiceRequest"
import { setUserNotifications } from "../../Notifications/NotificationSlice"
import { organizationNotificationGetRequest } from "../../Notifications/OrganizationNotificationServiceRequest"
import NotificationsDropdown from "../../Notifications/NotificationsDropdown"
import { onDashboardNavigation } from "./HeaderNavigationFunctions"
import { getOrganizationData } from "../../Organization/OrganizationDashboard/GetOrganizationServiceRequest"
import StorageService from "../../../App/StorageService"
import { chatListGetRequest } from "../../Chat/ChatServiceRequest"
import { Conversation } from "../../../Types/Conversation"
import { setHasUnseenMessages } from "../../Chat/ChatSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { getUserNotificationsSettingsState, getUserPaymentSettingsState, getUserPrivacySettingsState, getUserProfileSettingsState } from "../../User/UserSettings/UserSettingsSlice"

const accessToken = StorageService.getItem(StorageKey.accessToken) as string
let socketProtocol = window.location.host.startsWith("localhost") ? "ws" : "wss"
let socket = new WebSocket(`${socketProtocol}://${window.location.hostname}${socketProtocol == 'ws' ? ':8000' : ''}/ws/chat/`, ["Token", accessToken])

function Header(props: { roomHeader?: boolean }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false)
    const [isMobileSearchDropdownOpen, setIsMobileSearchDropdownOpen] = useState(false)
    const { id } = useParams()
    const orgId = SessionService.getItem(StorageKey.organizationId)
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice.hasAccessToken)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    let params = new URLSearchParams(location.search)
    const searchedQuery = params.get("searchedQuery")
    const [searchParams, setSearchParams] = useSearchParams()
    let searchRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let mobileSearchRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const searchPagePathnames = useMemo(() => [`/search-screen/${id}`, `/search-screen`], [id])
    const hasUnseenMessages = useSelector((state: RootState) => state.chatSlice.hasUnseenMessages)

    const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchParams.set("searchedQuery", event.target.value)
        setSearchParams(searchParams)
    }

    const onEnterHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const enter = "Enter"
        if (event.key === enter && searchedQuery !== null) {
            let query = searchedQuery.replace("#", "%23")
            switch (role) {
                case RoleType.USER:
                    setIsSearchDropdownOpen(false)
                    setIsMobileSearchDropdownOpen(false)
                    navigate(`/search-screen?searchedQuery=${query}`)
                    window.scrollTo(0, 0)
                    break
                case RoleType.ORGANIZATION:
                    if (orgId === id) {
                        navigate(`/search-screen/${id}?searchedQuery=${query}`)
                        window.scrollTo(0, 0)
                    } else {
                        navigate(`/search-screen/${orgId}?searchedQuery=${query}`)
                        window.scrollTo(0, 0)
                    }

                    break
                default:
                    break
            }
        }
    }

    useEffect(() => {
        if (id) {
            getOrganizationData(id!)
                .then(response => {
                    if (response.data.is_owner) {
                        dispatch(setOrganizationData(response.data.organization))
                        dispatch(setIsOwner(response.data.is_owner))
                        SessionService.setItem(StorageKey.organizationId, id!)
                    }
                    if (response.data.is_admin) {
                        dispatch(setOrganizationData(response.data.organization))
                        organizationNotificationGetRequest(id!)
                            .then(response => {
                                if (response.status === 200) {
                                    dispatch(setUserNotifications(response.data))
                                }
                            })
                            .catch(error => {
                                if (error.response.status === 403) {
                                    navigate("/no-permission")
                                }
                            })
                    }
                })
                .catch()
        } else {
            SessionService.setItem(StorageKey.roles, RoleType.USER)
        }
    }, [dispatch, id, navigate])

    useEffect(() => {
        if (isAuthenticated && role === RoleType.USER && !id) {
            userNotificationGetRequest().then(response => {
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            })
        }
    }, [id, dispatch, isAuthenticated, role])

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isSearchDropdownOpen && searchRef.current && !searchRef.current.contains(event.target as HTMLElement)) {
                setIsSearchDropdownOpen(false)
            }
            if (isMobileSearchDropdownOpen && mobileSearchRef.current && !mobileSearchRef.current.contains(event.target as HTMLElement)) {
                setIsMobileSearchDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)

        if (searchParams.has('settings')) {
            if (searchParams.get('settings') == 'notifications') {
                dispatch(setModalData({ open: true, settings: true }))
                dispatch(getUserNotificationsSettingsState(true))
                dispatch(getUserProfileSettingsState({ status: false, isHashtag: false }))
                dispatch(getUserPrivacySettingsState(false))
                dispatch(getUserPaymentSettingsState(false))
            }else if (searchParams.get('settings') == 'profile') {
                dispatch(setModalData({ open: true, settings: true }))
                dispatch(getUserNotificationsSettingsState(false))
                dispatch(getUserProfileSettingsState({ status: true, isHashtag: true }))
                dispatch(getUserPrivacySettingsState(false))
                dispatch(getUserPaymentSettingsState(false))
            }
        }
    })

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchedQuery !== null && searchedQuery!.length > 2 && !searchPagePathnames.includes(location.pathname)) {
                setIsSearchDropdownOpen(true)
                setIsMobileSearchDropdownOpen(true)
                setIsLoading(true)
                const hasHashtag = searchedQuery.charAt(0) === "#"
                onGetGlobalSearchFilteredData(searchedQuery.replace("#", "")!, 1, SearchFilter.all_results, hasHashtag)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setGlobalSearchData({ result: response.data.results }))
                            setIsLoading(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else if (searchedQuery !== null && searchedQuery?.length === 0) {
                setIsSearchDropdownOpen(false)
                setIsMobileSearchDropdownOpen(false)
                setIsLoading(true)
                dispatch(setGlobalSearchData({ result: [] }))
            } else if (searchedQuery === null) {
                setIsSearchDropdownOpen(false)
                setIsMobileSearchDropdownOpen(false)
                setIsLoading(true)
                dispatch(setGlobalSearchData({ result: [] }))
            }
        }, 500)

        return () => clearTimeout(getData)
    }, [dispatch, location.pathname, searchPagePathnames, searchedQuery])

    setTimeout(() => {
        dispatch(setRoleStatusData(false))
    }, 5000)

    return (
        <nav className={`header ${props.roomHeader && `header--room-header`}`}>
            <div className="header__logo" onClick={() => onDashboardNavigation(role, navigate, id!)}>
                <MediumLogoIcon />
            </div>
            <div className="header__search-bar" ref={searchRef}>
                <SearchInput
                    onRemoveIconClick={() => {
                        setSearchParams({ searchedQuery: "" })
                    }}
                    searchvalue={searchedQuery || ""}
                    onKeyDown={onEnterHandler}
                    onChange={onSearchInputChange}
                    isGlobalSearch={true}
                />
                {isSearchDropdownOpen && <SearchDropdown isLoading={isLoading} />}
            </div>
            <div className="header__menu">
                <div className="header__menu-home" onClick={() => onDashboardNavigation(role, navigate, id!)}>
                    <div className="header__icon">
                        <HomeIcon />
                    </div>
                </div>

                {isAuthenticated && role === RoleType.USER && (
                    <div className="header__menu-chat" onClick={() => navigate(`/chat`)}>
                        <div className="header__icon">
                            {hasUnseenMessages ?
                                (
                                    <ChatIconActive />
                                ) :
                                (
                                    <ChatIcon />
                                )}

                        </div>
                    </div>
                )}

                <NotificationsDropdown />
                <HeaderHelpMenu />
                <HeaderProfileMenu />
            </div>
            <div className="header__mobile-logo" onClick={() => onDashboardNavigation(role, navigate, id!)}>
                <EdusiiaSmallIcon />
            </div>
            <div className="header__mobile-search" ref={mobileSearchRef}>
                <SearchInput
                    onRemoveIconClick={() => {
                        setSearchParams({ searchedQuery: "" })
                    }}
                    onKeyDown={onEnterHandler}
                    searchvalue={searchedQuery! || ""}
                    onChange={onSearchInputChange}
                    isGlobalSearch={true}
                />
                {isMobileSearchDropdownOpen && <SearchDropdown isLoading={isLoading} />}
            </div>
            <div className="header__mobile-menu">
                <HeaderMobileMenu />
                <HeaderBurgerMenu />
            </div>
        </nav>
    )
}

export default Header
export { socket }
