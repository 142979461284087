import { useState } from "react"
import { EditorModifier, RoleType } from "../../../../App/enums"
import { organizationProfileDefault, profileDefault } from "../../../../App/GlobaleVariables"
import UserCard from "../UserCard"
import { Editor } from "@tinymce/tinymce-react"
import { onMention } from "./onMention"
import { getUsers } from "./getUsers"
import { ConvertStringToHTML, matchWord, replaceHTMLCharactersWithCorrespondingLetters } from "../../../../App/Helpers/Helpers"
import { EditorProps, TagType } from "../../../../Types/EditorTypes"

import { Editor as TinyMCEEditor } from "tinymce"
import useWindowSize from "../../Hooks/useWindowSize"
import { bool } from "prop-types"
import { useDispatch } from "react-redux"

function EditorTextArea({
    inputState,
    setInputState,
    enterPressed,
    editorRef,
    editorPlaceholder,
    setContent,
    editorHeight,
    modifier,
    initialEditorValue,
    isChat,
    isUserDashboard
}: EditorProps) {
    const [searchResult, setSearchResult] = useState<TagType[]>()
    const [searchState, setSearchState] = useState("idle")
    const spanArray: string[] = []
    const dropdown = document.getElementById("dropdown")
    const windowSize = useWindowSize()
    const breakpoint = 768
    const maxHeightNumber = windowSize >= breakpoint ? 191 : 100
    const dispatch = useDispatch()

    const debounce = (callback: (arg0: any) => void, wait: number | undefined) => {
        let timeoutId: number | undefined
        return (...args: any) => {
            window.clearTimeout(timeoutId)
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args)
            }, wait)
        }
    }

    const handleOnChange = debounce((e: React.FormEvent<HTMLTextAreaElement>) => {
        if (editorRef.current) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent())
            const spans = htmlContent.querySelectorAll("[data-type]")
            let content = editorRef.current.getContent({ format: "text" })
            const splitContentLines = content!.split("\n")
            if (isChat) {
                let content = editorRef.current.getContent({ format: "raw" })
                const splitContentLines = content!.split("\n")
                setContent!(editorRef!.current!.getContent({ format: "raw" }).length === 0 ? "" : content)!
            } else {
                let content = editorRef.current.getContent({ format: "text" })
                const splitContentLines = content!.split("\n")
                setContent!(editorRef!.current!.getContent().length === 0 ? "" : content)!
            }

            if (modifier !== EditorModifier.editRoomDescription && modifier !== EditorModifier.eventDescription) {
                var maxDivWidth = editorRef.current?.getContainer()!.offsetWidth! * 0.7
                const isEditContent = modifier === "edit-content"
                spans.forEach(span => {
                    spanArray.push(span.innerHTML.trim().replace("@", ""))
                })

                let rng = editorRef.current.selection.getRng()

                //const n = rng.commonAncestorContainer.textContent?.substring(rng.endOffset).match(/(^@[a-zA-Z0-9-_]+)( [a-zA-Z0-9-_]+)?/);
                const p = rng.commonAncestorContainer.textContent?.substring(0, rng.endOffset).match(/(@[a-zA-Z0-9-_]+)( [a-zA-Z0-9-_]+)?$/)

                if (p == null) {
                    return
                }

                let word = p![0] || ""
                if (word.length == 0) {
                    dropdown!.style!.visibility = "hidden"
                    return
                }

                word = word.replace("@", "")

                getUsers(word, setSearchResult, setSearchState, dropdown, content.slice(0, matchWord(content, word)), content, maxDivWidth, isEditContent)
            }

            setInputState(replaceHTMLCharactersWithCorrespondingLetters(editorRef.current.getContent()))
        }
    }, 150)

    const onUserClick = (user: TagType) => {
        dropdown!.style!.visibility = "hidden"
        dropdown!.style.width = "0"
        editorRef.current?.setContent(onMention(inputState, user, spanArray))
        setInputState(onMention(inputState, user, spanArray))
        setSearchResult([])
    }

    const colorizeHashtagsOnSpace = (editor: TinyMCEEditor) => {
        /*let content = editor.getContent().replaceAll("&nbsp;", " ")
        let regex = /(^|\s|<p>)(?<!\w)(#[a-zA-Z\u00C0-\u017F\-\\_]{0,28}[a-zA-Z\u00C0-\u017F\-\\_]?)(?=[^\w]|$)(?!class="hashtag"|<\/span>)/gi

        if (replaceHTMLCharactersWithCorrespondingLetters(content).match(regex)) {
            content = replaceHTMLCharactersWithCorrespondingLetters(content).replace(regex, `$1<span class="hashtag" contenteditable="false">$2</span>&nbsp;`)
            editor.setContent(content)
            editor.selection.setCursorLocation(editor.getBody(), 1)
        }*/
    }

    return (
        <div className={`editor-container editor-container--${modifier}`}>
            <div className={`editor-input__at-dropdown-${modifier}`} style={{maxHeight:maxHeightNumber}} id="dropdown">
                <div className="editor-input__at-dropdown">
                    {searchResult?.map((user, index) => {
                        return (
                            <div
                                key={user.id + user.type}
                                onClick={() => {
                                    onUserClick(user)
                                }}
                            >
                                <UserCard
                                    imageSrc={
                                        user.type === RoleType.USER.toLowerCase()
                                            ? user.profile_photo?.url_path || profileDefault
                                            : user.photo?.url_path || organizationProfileDefault
                                    }
                                    text={user.type === RoleType.USER.toLowerCase() ? user.first_name + " " + user.last_name : user.name}
                                    subtext={""}
                                    profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                    userCardStyle={"mention-item"}
                                    leftPanelStyle={"left-panel"}
                                    middlePanelStyle={"middle-panel"}
                                    textStyleContainer={"text-container"}
                                    textStyle={"text"}
                                    textStyleMore25={"text"}
                                    subtextStyle={"subtext"}
                                    rightPanelStyle={"right-panel"}
                                    rightTextStyle={""}
                                    rightText={""}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>

                {/* @ts-ignore*/}
                <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    licenseKey="gpl"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={initialEditorValue}
                    init={{
                        menubar: false,
                        plugins:
                            modifier !== EditorModifier.editRoomDescription && modifier !== EditorModifier.eventDescription
                                ? "autoresize lists link autolink"
                                : "lists",
                        language: "de",
                        language_url: "/tiny_de.js",
                        block_formats: " Header 1=h1; Header 2=h2; Header 3=h3",
                        text_patterns: [
                            { start: "*", end: "*", format: "italic" },
                            { start: "**", end: "**", format: "bold" },
                            { start: "##", format: "h2" },
                            { start: "###", format: "h3" },
                            { start: "####", format: "h4" },
                            { start: "#####", format: "h5" },
                            { start: "######", format: "h6" },
                            { start: "1. ", cmd: "InsertOrderedList" },
                            { start: "* ", cmd: "InsertUnorderedList" },
                            { start: "- ", cmd: "InsertUnorderedList" },
                        ],
                        inline: false,
                        inline_boundaries: false,
                        toolbar: isChat
                            ? "insertfile undo redo | styleselect | bold italic |  outdent indent | link"
                            : "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                        statusbar: false,
                        content_style: `${
                            modifier === EditorModifier.editContent
                                ? "body{font-size: 14px;color: #375558;background-color:rgba(247, 247, 247, 0.75);}, html{cursor: text;}"
                                : modifier === EditorModifier.editRoomDescription
                                ? "body{font-size: 14px;color: #375558;background-color: #F4F4F4;border-radius:0.5rem!important;}, html{cursor: text;}"
                                : "body{font-size: 14px;color: #375558;}, html{cursor: text;}"
                        } `,
                        content_css: "/editor-tiny.css",
                        toolbar_location: isUserDashboard? "bottom": "auto",
                        autoresize_overflow_padding: 5,
                        autoresize_bottom_margin: 5,
                        min_height: editorHeight,
                        height: modifier === EditorModifier.editRoomDescription || modifier === EditorModifier.eventDescription ? editorHeight : undefined,
                        max_height: maxHeightNumber,
                        width: "100%",
                        max_width: 1728,
                        valid_elements:
                            "span[class|id|data-type|contenteditable|style],p[align|class|style],ul[align|class|style],ol[align|class|style],li[align|class|style],a[align|class|style|href],strong,em",
                        placeholder: editorPlaceholder,
                        max_chars: 2000,
                        relative_urls: false,
                        remove_script_host: true,
                        link_title: false,
                        setup: function (editor) {
                            if(isUserDashboard){
                                //document.getElementsByClassName("tox-editor-header")[0].classList.add("hidden")

                                /* editor.on("init",function(e){
                                    document.getElementsByClassName("tox-editor-header")[0].classList.add("hidden")
                                    document.getElementsByClassName("editor-container")[0].classList.add("toolbar-hidden")
                                })

                                editor.on("blur",function(e){
                                    document.getElementsByClassName("tox-editor-header")[0].classList.add("hidden")
                                    document.getElementsByClassName("editor-container")[0].classList.add("toolbar-hidden")
                                })

                                editor.on("focus",function(e){
                                    document.getElementsByClassName("tox-editor-header")[0].classList.remove("hidden")
                                    document.getElementsByClassName("editor-container")[0].classList.remove("toolbar-hidden")
                                }) */

                            }

                            editor.on("input", function (e) {
                                const selection = editor.selection.getRng()
                                const container = selection.startContainer
                                const offset = selection.startOffset
                                let content = container?.textContent!.charAt(offset - 1).replace(/\s$/g, " ")
                                if (content === " " || content === "\n" || content === "&nbsp;") {
                                    colorizeHashtagsOnSpace(editor)
                                }
                            })
                            if (isChat) {
                                editor.on("keydown", function (e) {
                                    if (e.key == "Enter" && !e.shiftKey) {
                                        enterPressed(true)
                                        e.preventDefault()
                                    }
                                })
                            }
                        },
                    }}
                    onEditorChange={handleOnChange}
                />
            <div
                id="caretPosition"
                style={{
                    position: "absolute",
                    visibility: "hidden",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                    color: "#375558",
                    fontWeight: "500",
                    maxWidth: `maxDivWidth`,
                }}
            ></div>
        </div>
    )
}

export default EditorTextArea
