import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../../App/Axios"
import { RootState } from "../../../App/store"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setVideoVisible } from "../../Shared/SharedSlices/ExplanationVideoSlice"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import Video from "../../../App/assets/EdusiaTutorial.mp4"
import VideoPreview from "../../../App/assets/VideoPreview.jpg"


function ShowTutorialModal() {

    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const isVideoVisible = useSelector((state: RootState) => state.explanationVideoSlice.isVideoVisible)
    const [isShowTutorialChecked, setIsShowTutorialChecked] = useState(true)

    const updateShowTutorialField = (data: { show_tutorial: boolean }) => {
        return HTTPService.patch(`/user/${userId}/profile/`, data, headers)
    }
    const closeModal = () => {
        dispatch(setModalData({ open: false, showTutorial: false }))

    }
    const handleCheckboxChange = (checkboxValue: boolean) => {
        updateShowTutorialField({ show_tutorial: true })
        setIsShowTutorialChecked(!isShowTutorialChecked)
    }
    const handleWatchVideoNow = () => {
        dispatch(setModalData({ open: true, showTutorial: true }))
        dispatch(setVideoVisible(true))
    }
    const handleWatchVideoLater = () => {
        closeModal()
    }

    useEffect(() => {
        updateShowTutorialField({ show_tutorial: false })
    }, [])

    return (
        <div className="show-tutorial-modal">
            <div className="show-tutorial-modal__title-container">
                <p className="show-tutorial-modal__title">{translation.viedoWelcomeText} </p>
                <div className="show-tutorial-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            {isVideoVisible ?
                <div className="show-tutorial-modal__video">
                    <video
                        width="100%"
                        height="100%"
                        controls
                        playsInline
                        preload="auto"
                        poster={VideoPreview}
                        data-setup='{}'
                    >
                        <source
                            src={Video}
                            type='video/mp4' />
                    </video>
                </div>
                :
                <>
                    <div className="show-tutorial-modal__content-container"  >
                        <div className="show-tutorial-modal__explanation-info" >
                            <p className="show-tutorial-modal__info"  >{translation.videoExplanationText} </p>
                        </div>
                        <div className="show-tutorial-modal__buttons-container">
                            <div className="show-tutorial-modal__button-size">
                                <Button
                                    onClick={handleWatchVideoNow}
                                    className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                                >
                                    {translation.watchNow}
                                </Button>

                            </div>
                            <div className="show-tutorial-modal__button-size">
                                <Button
                                    onClick={handleWatchVideoLater}
                                    className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                                >
                                    {translation.watchLater}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="show-tutorial-modal__checkbox">
                        <Checkbox
                            color="dark-blue"
                            checked={isShowTutorialChecked}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(event.target.checked)}
                        />
                        <p className="p3 show-tutorial-modal__checkbox-text">{translation.videoCheckboxText}</p>
                    </div>
                </>
            }
        </div>
    )
}

export default ShowTutorialModal
