import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { Room } from "../../../Types/Room"
import UserCard from "./UserCard"
import { LoadingAnimation } from "./SvgIcons"
import { organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { capitalizeFirstLetter, formatDate } from "../../../App/Helpers/Helpers"
import { CallerComponent, RoleType, RoomType } from "../../../App/enums"
import { convertTagElement } from "./Editor/convertTagElement"
import MessageBody from "./MessageBody"

function OrgaFeedItem(props: { type: RoleType.USER | RoleType.ORGANIZATION; rooms: Room[]; isLoading: boolean }) {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const onRoomNameClick = (roomId: number, organizationId: number) => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/room/${roomId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${organizationId}/${roomId}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onOrganizationNameClick = (organizationId: number) => {
        switch (role) {
            case RoleType.USER:
                navigate(`/organization-profile/${organizationId}/`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/organization-profile/${organizationId}/`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onNameClick = (id: number, type: string, organizationId: number) => {
        switch (type) {
            case RoleType.USER.toLowerCase():
                if (userDataState.id === id) {
                    navigate(`/user-profile`)
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${id}`)
                    window.scrollTo(0, 0)
                }
                break
            case RoleType.ORGANIZATION.toLowerCase():
                navigate(`/organization-profile/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onPostClick = (roomId: number, postId: number) => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/room-post/${roomId}/${postId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room-post/${id}/${roomId}/${postId}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    return (
        <>
            {props.isLoading ? (
                <>
                    <div className="orga-feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="orga-feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="orga-feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                </>
            ) : (
                <>
                    {props.rooms?.map(room => {
                        return room?.posts === undefined || room?.posts.length === 0 ? null : (
                            <div className="orga-feed-item" key={room.id}>
                                <div className="orga-feed-item__card">
                                    <div className="orga-feed-item--padding-1">
                                        <UserCard
                                            imageSrc={room.photo || roomDefaultPhoto}
                                            text={
                                                <p title={room.name} onClick={() => onRoomNameClick(room.id, room.organization?.id!)}>
                                                    {room.name}
                                                </p>
                                            }
                                            subtext={
                                                <p
                                                    className="orga-feed-item-room-card__overflow"
                                                    title={room?.organization?.name}
                                                    onClick={() => onOrganizationNameClick(room.organization?.id!)}
                                                >
                                                    {translation.by + " " + room?.organization?.name!}
                                                </p>
                                            }
                                            rightText={capitalizeFirstLetter(room.type === RoomType.Private ? translation.private : translation.public)}
                                            userCardStyle={"feed-item-room-card"}
                                            leftPanelStyle={"left-panel"}
                                            middlePanelStyle={"middle-panel"}
                                            rightPanelStyle={"right-panel"}
                                            profileImageStyle={"image"}
                                            textStyle={`${room.name.length >= 24 ? "textMore25" : "text"}`}
                                            subtextStyle={"subtext"}
                                            rightTextStyle={`right-text feed-item-room-card__right-text--${room.type}`}
                                            onLeftItemClick={() => onRoomNameClick(room.id, room.organization?.id!)}
                                            textStyleMore25={`${room.name.length >= 24 ? "textMore25" : "text"}`}
                                        />

                                        {room.posts.map(post => {
                                            const mentionedUsers = document.querySelectorAll("[data-type]")
                                            convertTagElement(mentionedUsers, post.tags!, role, id!, userDataState.id)
                                            return (
                                                <div key={post.id}>
                                                    <div className="orga-feed-item__text-container" onClick={() => onPostClick(room.id, post.id)}>
                                                        <div className="orga-feed-item__text">
                                                            {
                                                                <MessageBody
                                                                    showPreview={false}
                                                                    text={post.content}
                                                                    caller={CallerComponent.feed}
                                                                    showRemovePreviewIcon={false}
                                                                    showCollapsed={false}
                                                                    hashtags={post.hashtags}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                    <UserCard
                                                        imageSrc={
                                                            post?.content_type === RoleType.USER.toLowerCase()
                                                                ? post.content_object?.profile_photo?.url_path || profileDefault
                                                                : post.content_object?.photo?.url_path || organizationProfileDefault
                                                        }
                                                        text={
                                                            <div
                                                                onClick={
                                                                    post.content_object === null
                                                                        ? undefined
                                                                        : () => onNameClick(post.content_object?.id!, post.content_type, parseInt(id!))
                                                                }
                                                            >
                                                                {post?.content_type === RoleType.ORGANIZATION.toLowerCase()
                                                                    ? post.content_object === null
                                                                        ? translation.deletedOrganization
                                                                        : post.content_object?.name
                                                                    : post.content_object === null
                                                                    ? translation.deletedUser
                                                                    : post.content_object?.first_name + " " + post.content_object?.last_name}
                                                            </div>
                                                        }
                                                        rightText={
                                                            <>
                                                                <div className="orga-feed-item-profile-card__time">{formatDate(post.date, "HH:mm ")!}</div>
                                                                <div className="orga-feed-item-profile-card__date">{formatDate(post.date, "dd MMM")!} </div>
                                                            </>
                                                        }
                                                        userCardStyle={"feed-item-profile-card"}
                                                        leftPanelStyle={`left-panel   ${
                                                            post.content_object === null && "feed-item-profile-card__left-panel--deleted"
                                                        }`}
                                                        middlePanelStyle={`middle-panel`}
                                                        rightPanelStyle={"right-panel"}
                                                        profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                                        textStyle={`text ${post.content_object === null && "feed-item-profile-card__text--deleted"}`}
                                                        rightTextStyle={"right-text"}
                                                        onLeftItemClick={
                                                            post.content_object === null
                                                                ? undefined
                                                                : () => onNameClick(post.content_object?.id!, post.content_type, parseInt(id!))
                                                        }
                                                        textStyleMore25={`textMore25 ${
                                                            post.content_object === null && "feed-item-profile-card__textMore25--deleted"
                                                        }`}
                                                    />
                                                </div>
                                            )
                                        })}

                                        {props.type === RoleType.ORGANIZATION && room?.pending_requests_count! > 0 && (
                                            <div className="orga-feed-item__request">
                                                {room.pending_requests_count + " "}
                                                {room?.pending_requests_count === 1 ? translation.request : translation.requests}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
        </>
    )
}

export default OrgaFeedItem
