import React, { useEffect, useState } from "react"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import IndividualProfileCard from "../../Shared/Components/IndividualProfileCard"
import MainLayout from "../../Shared/Layout/MainLayout"
import ModalGroup from "../../Shared/Components/ModalGroup"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import OrganizationProfileCoverPhoto from "./OrganizationProfileCoverPhoto"
import OrganizationProfilePinboardContainer from "./OrganizationPinboard/OrganizationProfilePinboardContainer"
import GoPremiumCard from "../../Shared/Components/GoPremiumCard"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RoleType, StorageKey, SubscriptionType } from "../../../App/enums"
import NewestOrganizationsCard from "../../Shared/NewestOrganizations/NewestOrganizations"
import OrganizationProfileHeader from "./OrganizationProfileHeader"
import OrganizationProfileDescription from "./OrganizationDescription/OrganizationProfileDescription"
import OrganizationProfileAdminSection, { AdminInfoType } from "./OrganizationAdmins/OrganizationProfileAdminSection"
import OrganizationProfileOurRooms from "./OrganizationRooms/OrganizationProfileOurRooms"
import OrganizationProfileEmployees from "./OrganizationProfileEmpoyees/OrganizationProfileEmployees"
import { useParams } from "react-router-dom"
import OrganizationProfileCard from "../../Shared/Components/OrganizationProfileCard"
import { RootState } from "../../../App/store"
import LoadingScreen from "../../Shared/Layout/LoadingScreen"
import { getLoadingStatus } from "../../Shared/SharedSlices/LoadingScreenSlice"
import { setRoleData, setRoleStatusData } from "../../Shared/SharedSlices/RolesSlice"
import SessionService from "../../../App/SessionService"
import { Organization } from "../../../Types/Organization"
import { setIsOwner, setOrganizationData } from "../../Shared/SharedSlices/OrganizationsSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import { getOrganizationData } from "../OrganizationDashboard/GetOrganizationServiceRequest"

function OrganizationProfileScreen() {
    const translation = useSelector(selectTranslations)
    const { id, otherOrgId, eventId } = useParams()
    const dispatch = useDispatch()
    const [isYourOrg, setIsYourOrg] = useState(false)
    const [admins, setAdmins] = useState<AdminInfoType[]>([])
    const isOwner = useSelector((state: RootState) => state.organizationsSlice.isOwnerSlice.isOwner)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [otherOrganizationData, setOtherOrganizationData] = useState<Organization>()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const loadingStatus = useSelector((state: RootState) => state.LoadingStatusSlice.status)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const removeAdmin = useSelector((state: RootState) => state.modalSlice.removeAdminModal?.removedAdmin)
    const transferOwnership = useSelector((state: RootState) => state.modalSlice.successfullyOwnershipTransfer)

    useEffect(() => {
        dispatch(getLoadingStatus(true))

        if (userDataState.id && id) {
            getOrganizationData(id!)
                .then(response => {
                    if (response.status === 200) {
                        setIsYourOrg(response.data.is_admin)
                        if (response.data.is_admin) {
                            dispatch(setRoleStatusData(true))
                            dispatch(setRoleData(RoleType.ORGANIZATION))
                            SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                            SessionService.setItem(StorageKey.organizationId, id!)
                            dispatch(setOrganizationData(response.data.organization))
                        } else {
                            setOtherOrganizationData(response.data.organization)
                        }

                        setAdmins(response.data.admins)
                        dispatch(setIsOwner(response.data.is_owner))

                        dispatch(getLoadingStatus(false))
                    }
                })
                .catch()
        } else if (otherOrgId) {
            getOrganizationData(otherOrgId!).then(response => {
                if (response.status === 200) {
                    setIsYourOrg(response.data.is_admin)
                    if (response.data.is_admin) {
                        dispatch(setRoleStatusData(true))
                        dispatch(setRoleData(RoleType.ORGANIZATION))
                        SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                        SessionService.setItem(StorageKey.organizationId, id!)
                        setAdmins(response.data.admins)
                    }
                    dispatch(getLoadingStatus(false))
                }
            })
        }
    }, [otherOrgId, id, dispatch, eventId, userDataState.id])

    useEffect(() => {
        if (removeAdmin) {
            dispatch(getLoadingStatus(true))
            getOrganizationData(id!)
                .then(response => {
                    if (response.status === 200) {
                        setAdmins(response.data.admins)
                        dispatch(getLoadingStatus(false))
                        dispatch(
                            setModalData({
                                open: true,
                                removeAdminModal: {
                                    status: false,
                                    admin: {
                                        first_name: "",
                                        id: 0,
                                        last_name: "",
                                        profile_photo: {
                                            id: 0,
                                            url_path: "",
                                        },
                                        role_id: 0,
                                    },
                                    removedAdmin: false,
                                },
                                adminRemoved: true,
                            })
                        )
                    }
                })
                .catch()
        }
    }, [dispatch, id, removeAdmin])

    useEffect(() => {
        if (transferOwnership) {
            getOrganizationData(id!)
                .then(response => {
                    if (response.status === 200) {
                        setAdmins(response.data.admins)
                        dispatch(setIsOwner(response.data.is_owner))
                    }
                })
                .catch()
        }
    }, [dispatch, id, transferOwnership])

    return (
        <>
            {loadingStatus ? (
                <LoadingScreen />
            ) : (
                <BackgroundWrapper>
                    <Header />
                    <OrganizationProfileCoverPhoto isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                    <MainLayout
                        leftPanelFirstComponent={role === RoleType.ORGANIZATION ? <IndividualProfileCard /> : <OrganizationProfileCard />}
                        leftPanelSecondComponent={
                            role === RoleType.ORGANIZATION ? <RoomDropdown type={RoleType.ORGANIZATION} /> : <RoomDropdown type={RoleType.USER} />
                        }
                        leftPanelForthComponent={role === RoleType.ORGANIZATION ? null : translation.newestOrganizations}
                        leftPanelFifthComponent={role === RoleType.ORGANIZATION ? null : <NewestOrganizationsCard />}
                        RightPanelFirstComponent={<OrganizationProfilePinboardContainer isYourOrganization={isYourOrg} hasTitle={true} />}
                        leftPanelThirdComponent={
                            role === RoleType.ORGANIZATION ? (
                                organization.subscription_type === SubscriptionType.free ? (
                                    <GoPremiumCard shape="tall" text={translation.upgradeYourProfile} />
                                ) : null
                            ) : null
                        }
                        noPadding={true}
                    >
                        <div className="organization-profile-content">
                            <OrganizationProfileHeader isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                            <OrganizationProfileDescription isEditable={true} isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                            <OrganizationProfileAdminSection isYourOrganization={isYourOrg} admins={admins} isOwner={isOwner} />
                            <OrganizationProfileOurRooms
                                isExpandedScreen={false}
                                isYourOrganization={isYourOrg}
                                hasSearchCategories={true}
                                hasButton={true}
                                filterNumber={3}
                                hasSearch={false}
                                createRoom={isYourOrg}
                            />
                            <OrganizationProfileEmployees
                                isExpandedScreen={false}
                                isYourOrganization={isYourOrg}
                                hasSearchCategories={false}
                                hasSearch={false}
                                filterTagNumber={3}
                                isEditable={isYourOrg}
                                hasButton={true}
                                filterNumber={4}
                            />
                            <div className="organization-profile-content__mobile-pinboard-container">
                                <OrganizationProfilePinboardContainer isYourOrganization={isYourOrg} hasTitle={true} />
                            </div>
                        </div>
                    </MainLayout>
                    <Footer />
                    <ModalGroup />
                </BackgroundWrapper>
            )}
        </>
    )
}

export default OrganizationProfileScreen
