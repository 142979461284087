import React from "react"

function DropdownItem(props: { name: string }) {
    return (
        <div className="dropdown-element">
            <p className="dropdown-element__text" title={props.name}>
                {" "}
                {props.name}
            </p>
        </div>
    )
}
export default DropdownItem
