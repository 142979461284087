import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { PhotoTypeModal } from "../../../App/enums"
import { green, organizationProfileDefault } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { Organization } from "../../../Types/Organization"
import { LoadingAnimation, LocationIcon, NetworkIcon, EditProfileIcon, LocationSmallIcon, NetworkSmallIcon } from "../../Shared/Components/SvgIcons"
import { onHashtagClick } from "../../Shared/Hashtag/onHashtagClick"
import PhotoDropdown from "../../Shared/PhotoDropdown/PhotoDropdown"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getOrganizationProfilePhotoDropdownStatus, getOtherOrganizationPhotoUrl } from "../../Shared/SharedSlices/PhotoDropdownSlice"
import { getOrganizationProfileSettingsState } from "../OrganizationSettings/OrganizationSettingsSlice"
import Image from "../../Shared/Primitive/Image"

function OrganizationProfileHeader(props: { isYourOrganization: boolean; otherOrganization: Organization }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const organziationProfilePhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.organizationProfilePhotoDropdownStatusSlice)
    const loadingOrganizationProfilePhotoDropdownStatus = useSelector(
        (state: RootState) => state.photoDropdownSlice.loadingOrganizationProfilePhotoDropdownStatusSlice
    )
    const dispatch = useDispatch()

    const onOrganizationSettingsModalOpen = () => {
        dispatch(setModalData({ open: true, organizationSettings: true }))
        dispatch(getOrganizationProfileSettingsState(true))
    }

    const onAddLocationClick = () => {
        if (props.isYourOrganization) {
                dispatch(setModalData({ open: true, organizationSettings: true }))
                dispatch(getOrganizationProfileSettingsState(true))
            }
    }

    const onProfilePhotoModalOpen = () => {
        if (props.otherOrganization?.photo?.url_path) {
            dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.OTHER_ORGANIZATION_PROFILE }))
            dispatch(getOtherOrganizationPhotoUrl({ url: props.otherOrganization?.photo?.url_path }))
        }
        return
    }

    return (
        <div className="organization-profile-header">
            <div className="organization-profile-header__upper-panel">
                <div className="organization-profile-header__image-container">
                    {loadingOrganizationProfilePhotoDropdownStatus.loading ? (
                        <div
                            className="organization-profile-header__profile-image-loading"
                            style={{ backgroundImage: `url(${organizationDataState.photo?.url_path})` }}
                        >
                            <LoadingAnimation type="cover" />
                        </div>
                    ) : props.isYourOrganization ? (
                        <Image
                            className="organization-profile-header__profile-image"
                            src={organizationDataState.photo?.url_path || organizationProfileDefault}
                            onClick={() => dispatch(getOrganizationProfilePhotoDropdownStatus({ status: !organziationProfilePhotoDropdownStatus.status }))}
                            alt="Organization"
                        />
                    ) : (
                        <Image
                            className="organization-profile-header__profile-image"
                            src={props.otherOrganization?.photo?.url_path || organizationProfileDefault}
                            onClick={onProfilePhotoModalOpen}
                            alt="Organization"
                        />
                    )}

                    {organziationProfilePhotoDropdownStatus.status && (
                        <PhotoDropdown
                            type={PhotoTypeModal.ORGANIZATION_PROFILE}
                            currentImage={organizationDataState.photo?.url_path!}
                            viewPhoto={translation.viewPhotoProfile}
                            changePhoto={translation.changePhotoProfile}
                            photoCropAspect={2 / 2}
                            recommendedResolution={translation.recommendedProfilePhoto}
                        />
                    )}
                </div>

                <div className="organization-profile-header__content-container">
                    <div className="organization-profile-header__name-container">
                        <div className="organization-profile-header__name">
                            {props.isYourOrganization ? organizationDataState.name : props.otherOrganization?.name}
                        </div>
                    </div>
                    <div className="organization-profile-header__tags-container">
                        {props.isYourOrganization ? (
                            organizationDataState.hashtags?.length! > 0 ? (
                                organizationDataState.hashtags?.map((hashtag, index) => (
                                    <p
                                        className="organization-profile-header__tag"
                                        key={index}
                                        onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                    >
                                        {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                    </p>
                                ))
                            ) : (
                                <div className="item__subtext-container">
                                    <p className="item__subtext" title={translation.blurryHashtag} onClick={onOrganizationSettingsModalOpen}>
                                        {translation.hashtag}
                                    </p>
                                    <p
                                        className="item__subtext item__subtext--margin-right"
                                        onClick={onOrganizationSettingsModalOpen}
                                        title={translation.blurryHashtag}
                                    >
                                        {translation.hashtag}
                                    </p>
                                </div>
                            )
                        ) : (
                            props.otherOrganization?.hashtags?.map((hashtag, index) => (
                                <p
                                    className="organization-profile-header__tag"
                                    key={index}
                                    onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                >
                                    {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                </p>
                            ))
                        )}
                    </div>

                    {props.isYourOrganization
                        ? organizationDataState.location_state && (
                              <div className="organization-profile-header__location-container">
                                  {organizationDataState.location_state?.id < 52 && (
                                      <>
                                          <div className="organization-profile-header__location-icon">
                                              <LocationIcon />
                                          </div>
                                          <div className="organization-profile-header__location-icon-small">
                                              <LocationSmallIcon />
                                          </div>
                                          <div className="organization-profile-header__location-text">
                                              {organizationDataState.location_state?.name_de} , {organizationDataState.location_state?.country.abbreviation}
                                          </div>
                                      </>
                                  )}

                                  {organizationDataState.location_state?.id >= 52 && (
                                      <>
                                          <div className="organization-profile-header__location-icon">
                                              <LocationIcon />
                                          </div>
                                          <p className="organization-profile-header__location-text organization-profile-header__add-location-link" onClick={onAddLocationClick}>
                                              {translation.addLocation}
                                          </p>
                                      </>
                                  )}
                              </div>
                          )
                        : props.otherOrganization?.location_state &&
                          props.otherOrganization.location_state.id < 52 && (
                              <div className="organization-profile-header__location-container">
                                  <div className="organization-profile-header__location-icon">
                                      <LocationIcon />
                                  </div>
                                  <div className="organization-profile-header__location-icon-small">
                                      <LocationSmallIcon />
                                  </div>
                                  <div className="organization-profile-header__location-text">
                                      {props.otherOrganization?.location_state?.name_de} , {props.otherOrganization?.location_state?.country.abbreviation}
                                  </div>
                              </div>
                          )}
                    {props.isYourOrganization
                        ? organizationDataState.website_url && (
                              <div className="organization-profile-header__url-container">
                                  <div className="organization-profile-header__url-icon">
                                      <NetworkIcon />
                                  </div>
                                  <div className="organization-profile-header__url-icon-small">
                                      <NetworkSmallIcon />
                                  </div>
                                  <a
                                      href={` ${organizationDataState.website_url}/`}
                                      rel="noreferrer"
                                      target="_blank"
                                      className="organization-profile-header__url-text"
                                  >
                                      {organizationDataState.website_url}
                                  </a>
                              </div>
                          )
                        : props.otherOrganization?.website_url && (
                              <div className="organization-profile-header__url-container">
                                  <div className="organization-profile-header__url-icon">
                                      <NetworkIcon />
                                  </div>
                                  <div className="organization-profile-header__url-icon-small">
                                      <NetworkSmallIcon />
                                  </div>
                                  <a
                                      href={` ${props.otherOrganization?.website_url}/`}
                                      rel="noreferrer"
                                      target="_blank"
                                      className="organization-profile-header__url-text"
                                  >
                                      {props.otherOrganization?.website_url}
                                  </a>
                              </div>
                          )}
                    {props.isYourOrganization && (
                        <div onClick={onOrganizationSettingsModalOpen} className="organization-profile-header__edit-icon">
                            <EditProfileIcon modifier="small" color={green} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OrganizationProfileHeader
