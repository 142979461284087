import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../../Types/User"

const initialState = {} as User

const UserDataSlice = createSlice({
    name: "UserData",
    initialState: initialState,
    reducers: {
        setUserData(state, action: PayloadAction<User>) {
            state.id = action.payload.id
            state.email = action.payload.email
            state.location = action.payload.location
            state.cover_photo = action.payload.cover_photo
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.description = action.payload.description
            state.edusiia_reasons = action.payload.edusiia_reasons
            state.profile_photo = action.payload.profile_photo
            state.privacy_settings = action.payload.privacy_settings
            state.hashtags= action.payload.hashtags
            state.location_state = action.payload.location_state
        },
    },
})
export const { setUserData } = UserDataSlice.actions
export default UserDataSlice.reducer
